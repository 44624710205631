// COLORS
$primary-color: purple;
$secondary-color: magenta;

$white-color: #efefef;
//$white-color: #ddd;

$dark-grey: #333333;
$light-grey: #d2d2d2;

$dark-blue-text: rgb(12, 4, 10); // For Headings

// FONT SIZE
$default-font-size: 1.6rem;
$big-font-size: 4rem;
$mid-font-size: 2.5rem;
